@include base-content();
a {
    transition: color .2s;
}
p {
    margin: $baseline 0 0;
}
.sb-admin {
    color: $text-color-regular;
}
