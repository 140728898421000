.sb-admin-panel {
    background-color: $window-background-color;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.sb-advanced-search {
    margin-top: $baseline;
    @include breakout($outer-gutters) {
        padding: 0 $value;
    }
}
.sb-admin-tools-header .sb-admin-status {
    @include typography(text-small);
    line-height: 1;
}
.sb-admin-body {
    @include breakout($outer-gutters) {
        padding: $baseline / 2 $value;
    }
    & > .message {
        margin-top: $baseline * 2;
        width: -moz-fit-content;
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
    }
    .sb-section,
    .sb-expander,
    .sb-admin-list {
        margin-top: $baseline;
        margin-bottom: 0;
    }
}

.sb-admin-panel-controls:empty {
    display: none;
}
.sb-admin-panel-controls,
.sb-admin-foot {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.sb-admin-panel-controls {
    flex-wrap: wrap;
    width: 100%;
    .sb-quick-search {
        margin-top: $baseline;
    }
    @include breakout($outer-gutters) {
        padding: 0 $value;
    }
}
//search inside .sb-admin-panel-controls
//found on pages list
.sb-admin-panel-search {
    flex-grow: 1;
}

.sb-admin-foot {
    overflow: hidden;
    background: $dark-gray;
    color: $text-color-white;
    padding: $baseline / 2 $baseline;
    @include breakout($outer-gutters) {
        margin: $baseline $value;
    }
    .sb-pagination {
        flex-shrink: 0;
    }
}
.sb-edit-dates {
    @include typography(text-small);
    span {
        margin-right: 1.5em;
    }
}
.sb-admin-foot:empty {
    display: none;
}
.sb-admin-body .sb-admin-no-results {
    background: $dark-gray;
    color: $text-color-white;
    padding: 0.5rem $baseline;
}

// XXX Added for re-ordering layout admin expanders:
.sb-admin-edit-groups {
    display: flex;
    flex-direction: column;

    #sb-section-general {
        order: 1;
    }

    #sb-child-content {
        order: 2;
    }

    #sb-expander-page {
        order: 3;
    }

    #sb-expander-seo {
        order: 4;
    }

    #sb-expander-og {
        order: 5;
    }
}
