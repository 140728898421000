@import 'admin:variables';
@import '@foundation/util/util';
@import '@foundation/components/visibility';
@import '@core/utility';
@import 'normalize';
@import 'mixins';

$breakpoints: (
  small: 0,
  smallplus: 400px,
  medium: 668px,
  large: 1020px,
  xlarge: 1400px,
  xxlarge: 1900px,
) !default;
$breakpoint-classes: (small smallplus medium large xlarge xxlarge) !default;

@include normalize;
@include foundation-visibility-classes;
@include global-styles(
    $scroll-type: auto
);
