//TODO: make admin follow the same icon standard as sites currently do, then include and use the same mixin
%icon-hide-text {
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    color: transparent;
}
.icon-button {
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;
    @extend %icon-hide-text;
}
.icon-button-text {
    display: inline-block;
    background-repeat: no-repeat;
    background-position: left center;
}
.icon-add:not(.icon) {
    @include sb-icon-add($secondary-color);
}
.icon-delete:not(.icon) {
    @include sb-icon-delete($secondary-color);
}
.icon-undelete:not(.icon) {
    @include sb-icon-undelete($secondary-color);
}
.icon-empty-trash:not(.icon) {
    @include sb-icon-empty-trash($secondary-color);
}
.icon-edit:not(.icon) {
    @include sb-icon-edit($secondary-color);
}
.icon-view:not(.icon) {
    @include sb-icon-view($secondary-color);
}
.icon-arrow-left:not(.icon) {
    @include sb-icon-arrow-left($secondary-color);
}
.icon-check:not(.icon) {
    @include sb-icon-check($secondary-color);
}
.icon-close:not(.icon) {
    @include sb-icon-close($secondary-color);
}
.icon-refresh:not(.icon) {
    @include sb-icon-refresh($secondary-color);
}
.icon-help:not(.icon) {
    @include sb-icon-help($secondary-color);
}
.icon-cancel:not(.icon) {
    @include sb-icon-cancel($secondary-color);
}
.icon-select:not(.icon) {
    @include sb-icon-select($secondary-color);
}
.icon-cut:not(.icon) {
    @include sb-icon-cut($secondary-color);
}
.icon-paste:not(.icon) {
    @include sb-icon-paste($secondary-color);
}
.icon-crop:not(.icon) {
    @include sb-icon-crop($secondary-color);
}

.sb-icon-button.icon-arrow-left {
    @include sb-icon-arrow-left($white);
}
.add-button {
    @include sb-icon-add(#fff);
}
.empty-button {
    @include sb-icon-delete(#fff);
//  background-size: auto 1.25rem; 
}
.icon-hidden:not(.icon) {
    @include sb-icon-hidden($text-color-medium);
}
.icon-locked:not(.icon) {
    @include sb-icon-lock($text-color-medium);
}
.sb-icon.icon-hidden {
    @include sb-icon($icon-size: 30px, $hide-text:true);
}


.image-add,
.text-icon-button.add {
    @include sb-icon-add($secondary-color);  
}
.image-add {
    &:hover,
    &:focus {
        @include sb-icon-add;
    }
}
.image-delete,
.delete {
    @include sb-icon-delete($secondary-color);
}
.image-delete {
    &:hover,
    &:focus {
        @include sb-icon-delete;
    }
}
.undelete {
    @include sb-icon-delete($secondary-color);
}

// .image-lock,
// input:disabled ~ .input-group-button .sb-field-unlock,
// .locked-field.locked ~ .input-group-button .sb-field-unlock,
// input:disabled ~ .input-group-button .sb-permalink-unlock {
//     @include sb-icon-lock($secondary-color);

//     &:hover,
//     &:focus {
//         @include sb-icon-lock($anchor-color-hover);
//     }
// }
// .image-help {
//     @include sb-icon-help($secondary-color);

//     &:hover,
//     &:focus {
//         @include sb-icon-help($anchor-color-hover);
//     }
// }
// .sb-field-unlock,
// .sb-permalink-unlock {
//     @include sb-icon-unlock($secondary-color);

//     &:hover,
//     &:focus {
//         @include sb-icon-unlock($anchor-color-hover);
//     }
// }
.view {
    @include sb-icon-view($secondary-color);
}
.edit {
    @include sb-icon-edit($secondary-color);
}

.icon svg {
    fill: $primary-color;
}

.sb-icon {
    @include sb-icon(16px);
    display: inline-block;
    font-size: 16px;
    line-height: 16px;
}
.sb-icon-close {
    @include sb-icon(16px, true);
    @include sb-icon-close(#555);
    opacity: .6;
    &:hover {
        opacity: 1;
    }
}

//child module icons
.sb-icon-default {
    @include sb-icon-edit($white);
}

