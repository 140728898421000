.sb-admin-journal-entry {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr;
    grid-auto-flow: dense;
    grid-column-gap: $gutter;
    grid-row-gap: $baseline / 2;
    margin-top: $baseline;
    @include breakpoint(medium up) {
        grid-column-gap: $gutter * 1.5;
    }
    & + & {
        margin-top: $baseline * 1.5;
    }
}
.sb-admin-journal-avatar {
    @include object-fit;
    @include sb-icon-user($primary-color);
    grid-column: 1;
    grid-row: 1;
    display: flex;
    width: $baseline * 3;
    height: $baseline * 3;
    border: 2px solid $off-white;
    border-radius: 50%;
    background-color: $white;
    background-size: 50% 50%;
    background-position: center;
    background-repeat: no-repeat;
    @include breakpoint(medium up) {
        grid-row-end: span 2;
        width: $baseline * 5;
        height: $baseline * 5;
    }
}
.sb-admin-journal-author {
    @include typography(header-base);
    align-self: center;
}
.sb-admin-journal-diffs {
    grid-column-end: span 2;
    border-radius: .5rem;
    border-top-left-radius: 0;
    border: 1px solid $gray-300;
    background-color: $white;
    padding: $baseline / 2 $baseline;
    position: relative;
    @include breakpoint(medium up) {
        grid-column-end: span 1;
    }
}
.sb-admin-journal-stem {
    position: absolute;
    top: -1px;
    right: 100%;
    height: 0;
    width: 0;
    border-bottom: .75rem solid transparent;
    border-right: .375rem solid $gray-300;
    &::after {
        content: '';
        position: absolute;
        top: 1px;
        left: 2px;
        height: 0;
        width: 0;
        border-bottom: .75rem solid transparent;
        border-right: .375rem solid $white;
    }
    @include breakpoint(medium up) {
        &,
        &::after {
            border-bottom-width: 1rem;
            border-right-width: .75rem;
        }
    }
}
.sb-admin-journal-diff {
    display: grid;
    grid-template-columns: 1fr auto;
    .sb-admin-journal-diff-change {
        grid-column: 1;
        grid-row: 1;
    }
    .sb-admin-journal-diff-status {
        grid-column: 2;
        grid-row: 1;
        justify-self: end;
        align-self: start;
    }
    .sb-admin-journal-diff-buttons {
        grid-column: 1 / span 2;
        grid-row: 2;
    }
    & + & {
        margin-top: $baseline / 2;
        padding-top: $baseline / 2;
        border-top: 1px solid $gray-200;
    }
    img {
        max-width: $baseline * 2;
        max-height: $baseline * 2;
    }
}
.sb-admin-journal-diff-buttons {
    display: flex;
    font-size: (14/$em) * 1em;
    font-weight: $semibold;
    .sb-journal-view {
        flex-shrink: 0;
    }
}
.sb-admin-journal-diff-buttons-publish {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    flex-grow: 1;
    a {
        margin-left: 1em;
    }
}

//dialog
@include rte-content(
    $selector: '.sb-diff-body',
);
.sb-diff-body {
    margin-top: $baseline;
    max-width: 720px;
    ins {
        text-decoration: none;
    }
    ins.diffins,
    ins.diffmod {
        background-color: rgba(192,255,0,0.6);
        box-shadow: 0 0 0 (2/$em) * 1rem rgba(192,255,0,0.6);
        border-radius: (4/$em) * 1rem;
        text-decoration: none;
        img {
            box-shadow: 0 0 0 (2/$em) * 1rem rgba(192,255,0,0.6);
        }
    }
    del.diffdel,
    del.diffmod {
        color: $red;
        background-color: rgba($red, .15);
        box-shadow: 0 0 0 (2/$em) * 1rem rgba($red, .15);
        border-radius: (4/$em) * 1rem;
    }
}
