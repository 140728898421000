@include admin-tools();
@include admin-pages-all;

.brand-amira {
    @include admin-tools-colors();
}
.brand-smallbox {
    @include admin-tools-colors(
        $nav-link-color-hover: $lime,
        $nav-link-color-active: $lime,
        $accent-color: $lime
    );
}