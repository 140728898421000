.sb-pagination {
    ul {
        @include unstyled-list;
        display: flex;
        align-items: center;
    }
    li {
        margin-left: .75em;
        a {
            display: block;
        }
    }
    .sb-pagination-page {
        display: none;
        margin-left: .5em;
        @include breakpoint(medium up) {
            display: block;
        }
        &.active {
            display: block;
            a {
                color: $primary-color;
            }
        }
    }
    .sb-pagination-first,
    .sb-pagination-last,
    .sb-pagination-previous,
    .sb-pagination-next {
        a,
        span {
            display: block;
            width: $baseline * 2;
            height: $baseline * 2.5;
            background-repeat: no-repeat;
            background-position: center;
            background-size: $baseline * 2 $baseline * 2;

            text-indent: 100%;
            overflow: hidden;
            white-space: nowrap;
        }
    }
    .sb-pagination-previous,
    .sb-pagination-next {
        a,
        span {
            @include sb-icon-arrow($anchor-color);
        }
        a:hover,
        a:focus {
            @include sb-icon-arrow($anchor-color-hover);
        }
    }
    .sb-pagination-first,
    .sb-pagination-last {
        a,
        span {
            @include sb-icon-double-arrow($anchor-color);
        }
        a:hover,
        a:focus {
            @include sb-icon-double-arrow($anchor-color-hover);
        }
    }
    .sb-pagination-first,
    .sb-pagination-previous {
        transform: rotate(180deg);
    }
    .disabled {
        opacity: .4;
        cursor: default;
    }
}
