.sb-admin {
    dl {
        grid-template-columns: auto 1fr;
        grid-gap: $baseline / 2 $baseline * 2;        
    }
    dt {
        font-weight: $semibold;
        margin-top: $baseline / 2;
    }
    dd {
        margin: 0;
        & > * {
            margin-top: $baseline;
            &:first-child {
                margin-top: 0;
            }
        }
    }
    @include breakpoint(medium up) {
        dl {
            display: grid;
        }
        dt,
        dd {
            margin-top: 0;
        }
    }
    .logger-definitions {
        dt {
            margin-top: $baseline * 2;
        }
        dd {
            margin-top: $baseline;
            & > * {
                margin-top: $baseline;
                &:first-child {
                    margin-top: 0;
                }
            }
        }
        @include breakpoint(medium up) {
            grid-gap: $baseline * 2;
            dt,
            dd {
                margin-top: 0;
            }
        }
    }
}

.sb-admin table {
    border-collapse: collapse;
    width: 100%;
    thead,
    tfoot {
        border: 1px solid $gray-300;
        background-color: $off-white;
    }
    thead {
        font-weight: $semibold;
    }
    tbody {
        border: 1px solid $gray-300;
        background-color: $white;
    }
    tr:nth-child(even) {
        background-color: $off-white;
    }
    td {
        padding: $baseline * 3/4 $baseline;
    }
    .sb-button:first-child,
    .input:first-child {
        margin-top: 0;
    }
}

.sb-admin-list-toolbar {
    $button-padding: map-get(get-input-props($button-props, button-base), button-padding);
    position: relative;
    padding: get-side($button-padding, top) $baseline get-side($button-padding, bottom);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius:  map-get(get-input-props(), input-border-radius);
    border-top-right-radius:  map-get(get-input-props(), input-border-radius);
    overflow: hidden;
    background-color: $white;
    border: 1px solid $border-color;
    border-bottom: none;
    .sb-button.sb-button {
        border-radius: 0;
        padding-top: calc(#{get-side($button-padding, top)} - 1px);
        padding-bottom: calc(#{get-side($button-padding, bottom)} - 1px);
        padding-left: calc(#{$baseline} - 1px);
        padding-right: calc(#{$baseline} - 1px);

        margin-top: calc(0px - #{get-side($button-padding, top)});
        margin-bottom: calc(0px - #{get-side($button-padding, bottom)});
        line-height: inherit;
        .icon:first-child {
            margin-left: 0;
        }
        .icon:last-child {
            margin-right: 0;
        }
        // span {
        //     color: $text-color-white;
        // }
        & + .sb-button {
            margin-left: 1px;
        }
    }
    .sb-buttons-left .sb-button:first-child,
    & > .sb-button:first-child {
        margin-left: calc(0px - #{$baseline});
    }
    .sb-buttons-right .sb-button:last-child,
    & > .sb-button:last-child {
        margin-right: calc(0px - #{$baseline});
    }
}

.sb-admin-list-title {
    font-weight: $bold;
    & > *:not(:first-child) {
        margin-left: .5em;
        padding-left: .5em;
        border-left: 1px solid $text-color-light;
    }
    a {
        font-weight: $normal;
    }
}

.sb-admin-list-table-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $content-background-color;
    padding: $baseline * 3/4 $baseline;
    min-height: $baseline * 10;
    text-align: center;
    font-size: 18px;
    border: 1px solid $border-color;
}

.sb-admin-list-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    background: $dark-gray;
    color: $text-color-white;
    padding: $baseline / 2 $baseline;
    border: 1px solid $gray-900;
    border-top: 0;
    .sb-pagination {
        flex-shrink: 0;
    }
}

.sb-admin-list-table {
    word-wrap: break-word;
    .sb-icon {
        @include sb-icon($icon-size: 1rem, $hide-text: true);
        box-sizing: content-box;
        vertical-align: inherit;
    }
    tr {
        transition: background-color .2s,
                    box-shadow .3s ease;

        //actually used for selected...
        &.disabled {
            background-color: $light-gray;
            &:hover,
            &:focus {
                background-color: $light-gray;
            }
        }

        &[data-href] {
            cursor: pointer;
            &:hover,
            &:focus {
                background-color: rgba($anchor-color, .2);
            }
            &:focus-within {
                box-shadow: inset $baseline / -2 0 0 $anchor-color;
            }
        }
    }
}
.sb-inline-list-new-row td {
    vertical-align: top;
}

//columns
.sb-admin-list-thumbnail {
    width: $baseline * 6;
}
.sb-admin-list-head-shrink {
    width: (10/$em) * 1rem;
    white-space: nowrap;
}
.sb-admin-list-head-id,
.sb-admin-list-head-publish, 
.sb-admin-list-head-delete {
    width: (25/$em) * 1rem;
}

.sb-admin-list-head-priority {
    width: (20/$em) * 1rem;
}
.sb-admin-list-priority {
    // background: no-repeat center;
    // background-size: 1rem 1rem;
    // @include sb-icon-move($gray);
    cursor: move;
    color: $text-color-light;
    &:hover {
        color: $text-color-regular;
    }
}
